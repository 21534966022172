import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminUsers = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUsersByWorkspaceProfileMappedLabelValue: state => data => {
      const workspaceId = data.workspaceId
      const excluded = data.excluded

      const rawItems = state.data
      if (!rawItems.length) return []
      const itemsFiltered = rawItems.filter(filterObj => {
        return filterObj.profiles.find(findObj => {
          return (
            findObj.workspaceId === workspaceId &&
            findObj.status === 'active' &&
            !excluded.includes(findObj.id)
          )
        })
      })

      return itemsFiltered.map((item) => {
        const selectedUserProfile = item.profiles.find(fObj => fObj.workspaceId === workspaceId)
        return {
          label: selectedUserProfile.id,
          customLabel: item.firstName + ' ' + item.lastName,
          value: selectedUserProfile.id
        }
      })
    },
    getLmsSuperadminUsersByWorkspaceProfileMappedIdName: state => data => {
      const workspaceId = data.workspaceId
      const excluded = data.excluded

      const rawItems = state.data
      if (!rawItems.length) return []
      const itemsFiltered = rawItems.filter(filterObj => {
        return filterObj.profiles.find(findObj => {
          return (
            findObj.workspaceId === workspaceId &&
            findObj.status === 'active' &&
            !excluded.includes(findObj.id)
          )
        })
      })

      return itemsFiltered.map((item) => {
        const selectedUserProfile = item.profiles.find(fObj => fObj.workspaceId === workspaceId)
        return {
          id: selectedUserProfile.id,
          name: item.firstName + ' ' + item.lastName
        }
      })
    },
    getLmsSuperadminUsers: state => {
      return state.data
    },
    getLmsSuperadminUsersPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_USERS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_USERS_PAGINATION_SET (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_USERS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_USERS_PAGINATION_CLEAR (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminUsersGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const customParams = data && data.customParams ? (data.customParams ? `&${data.customParams}` : '') : ''

        commit('LMS_SUPERADMIN_USERS_CLEAR')
        commit('LMS_SUPERADMIN_USERS_PAGINATION_CLEAR')

        axios({
          method: 'get',
          url: `${api.sso}/v2/users${queryUrlParams}${customParams}&filters[status]=registered,active`,
          data: data
        }).then(response => {
          commit('LMS_SUPERADMIN_USERS_SET', response.data.data)
          commit('LMS_SUPERADMIN_USERS_PAGINATION_SET', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminUsers
