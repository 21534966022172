<template>
    <div class="filter__select">
        <filter-select-button
            :filter-select-button="label"
            :class="[
                { selected: selected || selectedExtra }
            ]"
            :selected="selected"
            :active="menuActive"
            @switch-menu="switchMenu"
        />
        <keep-alive>
            <filter-select-menu
                ref="filterselectmenu"
                v-if="menuActive"
                :filter-select-range-active="selectItem.rangeActive"
                :filter-select-range-only="selectItem.rangeOnly"
                :filter-select-range-items="selectItem.rangeItems"
                :filter-select-range-value="selectItem.rangeValue"
                :filter-select-title="toCapitalize(fromKebabCaseToString(selectItem.label))"
                :filter-select-items="selectItem.values || filterSelectItems"
                :filter-select-items-extra="filterSelectItemsExtra"
                :filter-select-type="selectItem.type"
                :filter-select-disabled="selectItem.disabled"
                :filter-select-prevent-clear="selectItem.preventClear"
                :filter-select-value="selectedData"
                :filter-select-value-extra="selectedDataExtra"
                :filter-select-preselected="selectItem.preselected"
                :filter-select-allowed="selectItem.allowed"
                :filter-select-get-pagination-getter="selectItem.getPaginationGetter"
                :filter-select-get-items-getter="selectItem.getItemsGetter"
                :filter-select-get-items-getter-mapped="selectItem.getItemsGetterMapped"
                :filter-select-get-items-dispatch="selectItem.getItemsDispatch"
                :filter-select-get-items-dispatch-data="selectItem.getItemsDispatchData"
                :filter-select-get-items-dispatch-params="selectItem.getItemsDispatchParams"
                :filter-select-item-name="selectItem.itemName"
                :filter-select-search-queryable="selectItem.searchQueryable"
                :filter-select-table-labels="selectItem.tableLabels"
                :class="menuActive ? 'active' : ''"
                :loading="requestPending"
                @emit-reset="resetFilter"
                @emit-submit="submitFilter"
                @emit-selected="setSelected"
                @emit-selected-extra="setSelectedExtra"
                @emit-filter="emitFilter"
                @emit-filter-extra="emitFilterExtra"
            />
        </keep-alive>
    </div>
</template>

<script>
import FilterSelectButton from '@/components/default/filters/FilterSelectButton'
import FilterSelectMenu from '@/components/default/filters/FilterSelectMenu'

export default {
  name: 'filter-select',
  components: {
    FilterSelectMenu,
    FilterSelectButton
  },
  data () {
    return {
      menuActive: false,
      selected: '',
      selectedExtra: '',
      selectedData: '',
      selectedDataExtra: '',
      requestPending: false
    }
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    filterSelectItems () {
      return this.$store.getters[this.selectItem.getItemsGetter]
    },
    filterSelectItemsExtra () {
      return this.$store.getters[this.selectItem.extraGetItemsGetter]
    },
    label () {
      let label
      let selected = this.selected
      let selectedExtra = this.selectedExtra
      if (selected && selectedExtra) {
        selectedExtra = ` & ${ selectedExtra.slice(selectedExtra.indexOf(' ')) }`
      }
      if (selected || selectedExtra) {
        label = selected + selectedExtra
      } else {
        label = this.toCapitalize(this.fromKebabCaseToString(this.selectItem.label))
      }
      return label
    }
  },
  methods: {
    switchMenu () {
      if (!this.menuActive) {
        this.$eventBus.$emit('filter-select-close-menus')
      }
      this.menuActive = !this.menuActive
    },
    closeOtherMenus () {
      this.menuActive = false
    },
    //
    setSelected (data) {
      this.selected = data
    },
    setSelectedExtra (data) {
      this.selectedExtra = data || ''
    },
    emitFilter (data) {
      this.selectedData = data
      this.$emit('emit-filter', {
        key: this.selectItem.key,
        label: this.selectItem.label,
        type: this.selectItem.type,
        values: data
      })
    },
    emitFilterExtra (data) {
      this.selectedDataExtra = data
      this.$emit('emit-filter', {
        key: this.selectItem.keyExtra,
        label: this.selectItem.label,
        type: 'multiselect',
        values: data
      })
    },
    //
    resetFilter () {
      this.menuActive = false
    },
    submitFilter () {
      this.menuActive = false
    },
    closeBox (event) {
      const path = event.path || event.composedPath()
      const button = this.$el.getElementsByClassName('filter__select_button')[0]

      if (!path || !this.menuActive) return

      let close = true
      for (let i = 0; i < path.length; i++) {
        if (!path[i].className) continue
        if (
            path[i].classList.contains('filter__select_menu') ||
            event.target === button
        ) {
          close = false
        }
      }
      if (close) {
        this.menuActive = false
      }
    }
  },
  mounted () {
    const overlay = document.getElementById('app')
    overlay.addEventListener('click', this.closeBox)
  },
  beforeDestroy () {
    const overlay = document.getElementById('app')
    overlay.removeEventListener('click', this.closeBox)
  },
  created () {
    const preselected = this.selectItem.preselected

    if (preselected) {
      this.emitFilter(preselected)
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/ebs/filters/filterselect";
</style>
