import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminWorkflows = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkflows: state => {
      const workflowsRaw = JSON.parse(JSON.stringify(state.data))
      const workflowsMapped = workflowsRaw ? workflowsRaw.map(workflowsObj => {
        let type

        for (const [key, value] of Object.entries(workflowsObj.workflow.targetAudience)) {
          if (value) type = key
        }

        return {
          ...workflowsObj,
          type
        }
      }) : []

      return workflowsMapped
    },
    getLmsSuperadminWorkflowsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminWorkflowsMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminWorkflowsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKFLOWS_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_WORKFLOWS_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_WORKFLOWS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKFLOWS_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkflowsGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const type = data && data.customParams && data.customParams.type ? `&type=${data.customParams.type}` : ''

        commit('LMS_SUPERADMIN_WORKFLOWS_CLEAR')
        commit('LMS_SUPERADMIN_WORKFLOWS_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.learningactivities}/workflows${queryUrlParams}${type}`
        }).then(function (response) {
          commit('LMS_SUPERADMIN_WORKFLOWS_SET', response.data.data !== undefined ? response.data.data : response.data)
          commit('LMS_SUPERADMIN_WORKFLOWS_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default lmsSuperadminWorkflows
