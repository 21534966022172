import axios from 'axios'
import router from '@/router'
import api from '@/api'

const data = {
  data: {
    createdBy: '',
    expireDate: '',
    id: '',
    invitedUser: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    roles: ['learner'],
    customerRoles: [],
    managerOrganisationUnits: [],
    status: '',
    workspaceId: '',
    invitations: [],
    workspaceName: '',
    customerId: '',
    customerName: '',
    toWorkspace: '',
    profiles: [],
    unit: '',
    suggestedCourses: [],
    combineSuggestedCourses: [],
    workspaceGroups: [],
    customFields: [],
    isUnitManager: false,
    api: false,
    user: {
      api: false
    }
  },
  default: ''
}

const lmsSuperadminWorkspaceUserProfile = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkspaceUserProfile: state => {
      return state.data
    },
    getLmsSuperadminWorkspaceUserProfileId: state => {
      return state.data.id
    },
    getLmsSuperadminWorkspaceUserProfileEmail: state => {
      return state.data.emailAddress
    },
    getLmsSuperadminWorkspaceUserProfileFirstName: state => {
      return state.data.firstName
    },
    getLmsSuperadminWorkspaceUserProfileLastName: state => {
      return state.data.lastName
    },
    getLmsSuperadminWorkspaceUserProfileAPI: state => {
      return state.data.api
    },
    getLmsSuperadminWorkspaceUserProfileStatus: state => {
      return state.data.status
    },
    getLmsSuperadminWorkspaceUserProfileManagerOrgUnits: state => {
      return state.data.managerOrganisationUnits
    },
    getLmsSuperadminWorkspaceUserProfileCustomerRoles: state => {
      return state.data.customerRoles
    },
    getLmsSuperadminWorkspaceUserProfileExpireDate: state => {
      return state.data.expireDate
    },
    getLmsSuperadminWorkspaceUserProfileIsUnitManager: state => {
      return state.data.isUnitManager
    },
    getLmsSuperadminWorkspaceUserProfileRoles: state => {
      return state.data.roles
    },
    getLmsSuperadminWorkspaceUserProfileUnit: state => {
      return state.data.unit
    },
    getLmsSuperadminWorkspaceUserDefaultProfileUnit: state => {
      return state.default.unit
    },
    getLmsSuperadminWorkspaceUserDefaultProfileRoles: state => {
      return state.default.roles || []
    },
    getLmsSuperadminWorkspaceUserProfileCombineSuggestedCourses: state => {
      return state.data.combineSuggestedCourses
    },
    getLmsSuperadminWorkspaceUserProfileSuggestedCourses: state => {
      const suggestedCourses = state.data.suggestedCourses
      return suggestedCourses
    },
    getLmsSuperadminWorkspaceUserProfileWorkspaceGroups: state => {
      return state.data.workspaceGroups
    },
    getLmsSuperadminWorkspaceUserProfileUserId: state => {
      return state.data.user ? state.data.user.id : ''
    },
    getLmsSuperadminWorkspaceUserProfileToWorkspace: state => {
      return state.data.toWorkspace
    },
    getLmsSuperadminWorkspaceUserProfileWorkspaceId: state => {
      return state.data.workspaceId
    },
    getLmsSuperadminWorkspaceUserProfileWorkspaceName: state => {
      return state.data.workspaceName
    },
    getLmsSuperadminWorkspaceUserProfileCustomerId: state => {
      return state.data.customerId
    },
    getLmsSuperadminWorkspaceUserProfileCustomerName: state => {
      return state.data.customerName
    },
    getLmsSuperadminWorkspaceUserProfileCustomFields: state => {
      return state.data.customFields
    },
    getLmsSuperadminWorkspaceUserProfileInvitationId: state => {
      return state.data.latestInvitation ? state.data.latestInvitation.id : ''
    },
    getLmsSuperadminWorkspaceUserProfileIsAlreadyInvited: state => {
      const toWorkspace = state.data.toWorkspace
      const invitations = state.data.invitations
      return invitations.find(obj => obj.toWorkspace === toWorkspace)
    },
    getLmsSuperadminWorkspaceUserProfileAlreadyExists: state => {
      const toWorkspace = state.data.toWorkspace
      const profiles = state.data.profiles
      return profiles && profiles.length ? profiles.find(obj => obj.workspaceId === toWorkspace) : false
    },
    getLmsSuperadminWorkspaceUserProfileIsDeleted: state => {
      const toWorkspace = state.data.toWorkspace
      const profiles = state.data.profiles
      const selectedProfile = profiles && profiles.length ? profiles.find(obj => obj.workspaceId === toWorkspace) : null
      return selectedProfile ? selectedProfile.status === 'deleted' : false
    },
    getLmsSuperadminWorkspaceUserProfileIsExpired: state => {
      const toWorkspace = state.data.toWorkspace
      const profiles = state.data.profiles
      const selectedProfile = profiles && profiles.length ? profiles.find(obj => obj.workspaceId === toWorkspace) : null
      return selectedProfile ? selectedProfile.status === 'expired' : false
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_GET (state, payload) {
      payload.firstName = payload.user.firstName
      payload.lastName = payload.user.lastName
      payload.unit = payload.status === 'active' ? payload.unit : (payload.latestInvitation ? payload.latestInvitation.unit : '')
      if (
        payload.latestInvitation &&
      payload.latestInvitation.status === 'sent' &&
      payload.id === payload.latestInvitation.profileId
      ) {
        payload.suggestedCourses = payload.latestInvitation.suggestedCourses
        payload.managerOrganisationUnits = payload.latestInvitation.managerOrgUnits || []
      } else {
        payload.suggestedCourses = []
        payload.managerOrganisationUnits = payload.managerOrgUnits || []
      }
      payload.combineSuggestedCourses = []
      payload.workspaceGroups = payload.status === 'active' ? payload.workspaceGroups.map(obj => obj.groupId) || [] : (payload.latestInvitation ? payload.latestInvitation.workspaceGroups : [])
      payload.customFields = payload.customFields.constructor === Object ? Object.keys(payload.customFields).map(obj => {
        const value = payload.customFields[obj]
        return {
          id: obj,
          value: value.string || value.set || value.date || value.number
        }
      }) : []
      state.data = payload
      state.default = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_DEFAULT (state) {
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    //
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ID (state, payload) {
      state.data.id = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_EMAIL (state, payload) {
      state.data.emailAddress = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_FIRSTNAME (state, payload) {
      state.data.firstName = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_LASTNAME (state, payload) {
      state.data.lastName = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ROLES (state, payload) {
      let roles = state.data.roles
      const roleIndex = roles.findIndex(obj => obj === payload.role)

      if (roleIndex > -1 && !payload.value) {
        roles.splice(roleIndex, 1)
      } else if (payload.value) {
        roles.push(payload.role)
      }
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_MANAGERORGUNITS (state, payload) {
      state.data.managerOrganisationUnits = payload.length ? payload.map(obj => obj.id) : []
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_TOWORKSPACE (state, payload) {
      state.data.toWorkspace = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACEID (state, payload) {
      state.data.workspaceId = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACENAME (state, payload) {
      state.data.workspaceName = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERID (state, payload) {
      state.data.customerId = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERNAME (state, payload) {
      state.data.customerName = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMFIELD (state, payload) {
      let customFields = JSON.parse(JSON.stringify(state.data.customFields))
      let existingCustomFieldIndex = customFields.findIndex(obj => obj.id === payload.id)

      if (existingCustomFieldIndex > -1) {
        if (payload.value !== '') {
          customFields[existingCustomFieldIndex].value = payload.value
        } else {
          customFields.splice(existingCustomFieldIndex, 1)
        }
      } else {
        customFields.push({
          id: payload.id,
          value: payload.value
        })
      }

      state.data.customFields = customFields
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ISUNITMANAGER (state, payload) {
      state.data.isUnitManager = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_STATUS (state, payload) {
      state.data.status = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_EXPIREDATE (state, payload) {
      state.data.expireDate = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITEDUSER (state, payload) {
      state.data.invitedUser = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_PROFILES (state, payload) {
      state.data.profiles = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITATIONS (state, payload) {
      state.data.invitations = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERROLES (state, payload) {
      state.data.customerRoles = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_UNIT (state, payload) {
      state.data.unit = payload ? payload.id : null
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACEGROUPS (state, payload) {
      state.data.workspaceGroups = payload.length ? payload.map(obj => obj.value) : []
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_SUGGESTEDCOURSES (state, payload) {
      state.data.suggestedCourses = payload.constructor === Array ? payload.map(obj => obj.value) : payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_COMBINE_SUGGESTEDCOURSES (state, payload) {
      state.data.combineSuggestedCourses = payload
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_COMBINE_SUGGESTEDCOURSES_SELECTED (state, payload) {
      const combineSuggestedCourses = state.data.combineSuggestedCourses
      let specificCourse = combineSuggestedCourses.find(obj => obj.id === payload.index)

      if (!specificCourse) return

      specificCourse.selected = payload.value
    },
    LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_COLLECT_SUGGESTEDCOURSES (state) {
      let coursesCombined = []
      let uniqueCoursesCombined
      const suggestedCourses = state.data.suggestedCourses
      const combineSuggestedCourses = state.data.combineSuggestedCourses.map(obj => obj.value)

      coursesCombined = [...suggestedCourses, ...combineSuggestedCourses]
      uniqueCoursesCombined = coursesCombined.filter((item, pos, self) => self.indexOf(item) === pos)

      state.data.suggestedCourses = uniqueCoursesCombined
    }
  },
  actions: {
    lmsSuperadminWorkspaceUserProfileClear (context) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_CLEAR')
    },
    lmsSuperadminWorkspaceUserProfileReset (context) {
      context.commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_RESET')
    },
    //
    lmsSuperadminWorkspaceUserProfileGet ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_CLEAR')
        axios({
          method: 'get',
          url: `${api.sso}/v2/user-profiles/${id}`
        }).then(response => {
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_GET', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileDelete (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.sso}/v2/delete-user-profile/${id}`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileReactivate (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/reactivate-user-profile/${id}`
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileCreate ({ state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const userProfilesWithUAOrMARole = getters.getLmsSuperadminUserProfilesWithUAOrMARole
        const defaultWorkspaceId = state.data.toWorkspace ? state.data.toWorkspace : (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataWorkspaceId : '')
        const defaultWorkspaceName = state.data.workspaceName ? state.data.workspaceName : (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataWorkspaceName : '')
        const defaultCustomerId = state.data.customerId ? state.data.customerId : (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataCustomerId : '')
        const defaultCustomerName = state.data.customerName ? state.data.customerName : (userProfilesWithUAOrMARole.length === 1 ? getters.getAuthUserSecondStageTokenDataCustomerName : '')
        const stateData = JSON.parse(JSON.stringify(state.data))
        const paramData = data ? JSON.parse(JSON.stringify(data)) : ''
        const redirect = paramData ? (paramData.redirect !== undefined ? paramData.redirect : true) : true
        const notifications = paramData ? (paramData.notifications !== undefined ? paramData.notifications : true) : true
        const isUnitManager = stateData.isUnitManager
        let dataOutput = paramData ? (paramData.emailAddress ? paramData : stateData) : stateData
        let rewrittenCustomFields = {}

        dataOutput.combineSuggestedCourses = undefined
        dataOutput.createdBy = getters.getAuthUserSecondStageTokenDataProfileID
        dataOutput.toWorkspace = paramData ? (paramData.toWorkspace !== undefined ? paramData.toWorkspace : defaultWorkspaceId) : defaultWorkspaceId
        dataOutput.workspaceName = paramData ? (paramData.workspaceName !== undefined ? paramData.workspaceName : defaultWorkspaceName) : defaultWorkspaceName
        dataOutput.customerId = paramData ? (paramData.customerId !== undefined ? paramData.customerId : defaultCustomerId) : defaultCustomerId
        dataOutput.customerName = paramData ? (paramData.customerName !== undefined ? paramData.customerName : defaultCustomerName) : defaultCustomerName
        dataOutput.selectedCourses = paramData && paramData.selectedCourses !== undefined && dataOutput.roles.includes('learner') ? paramData.selectedCourses : []
        dataOutput.suggestedCourses = dataOutput.roles.includes('learner') ? dataOutput.suggestedCourses : []
        dataOutput.emailAddress = paramData ? (paramData.emailAddress !== undefined ? paramData.emailAddress.toLowerCase() : stateData.emailAddress.toLowerCase()) : stateData.emailAddress.toLowerCase()
        dataOutput.bulk = !!data.bulk
        for (let field of dataOutput.customFields) {
          if (field.value) {
            rewrittenCustomFields[field.id] = field.value
          }
        }
        dataOutput.customFields = rewrittenCustomFields
        dataOutput.status = 'sent'
        dataOutput.unit = dataOutput.unit || null

        return axios({
          method: 'post',
          url: `${api.sso}/v2/invitations`,
          data: dataOutput
        }).then(response => {
          const invitationId = response.data.id
          const unitId = dataOutput.unit
          if (isUnitManager && unitId && invitationId) {
            dispatch('lmsUserInvitationGet', invitationId).then(invitationResponse => {
              const profileId = invitationResponse.profileId
              dispatch('lmsSuperadminOrgUnitAddManager', { unitId, profileId })
            })
          }
          if (notifications) {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Invitation create',
              message: `Invitation has been successfully created.`,
              status: 'success'
            })
          }
          if (redirect) {
            router.push({ name: 'lms-superadmin-workspace-edit-users', params: { id: dataOutput.toWorkspace } })
          }
          resolve(response)
        }).catch(error => {
          if (notifications) {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Invitation create',
              message: `Invitation hasn't been created.`,
              status: 'error'
            })
          }
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileUpdate ({ commit, state, getters, dispatch }, redirect = true) {
      return new Promise((resolve, reject) => {
        const workspaceId = JSON.parse(JSON.stringify(getters.getLmsSuperadminWorkspaceId))
        const stateData = JSON.parse(JSON.stringify(state.data))
        const stateDefaultData = JSON.parse(JSON.stringify(state.default))
        const workspaceCustomFields = getters.getLmsSuperadminWorkspaceCustomFieldsData
        let customFields = JSON.parse(JSON.stringify(stateData.customFields))
        customFields = customFields.filter(customFieldObj => workspaceCustomFields.findIndex(fObj => fObj.id === customFieldObj.id) > -1)
        let data = JSON.parse(JSON.stringify(state.data))
        const isUnitManager = data.isUnitManager
        data.userId = data.user.id
        data.unit = undefined
        data.customFields = customFields
        data.expireDate = data.expireDate || null

        const lmsUserAccountUpdateDetails = () => {
          const userData = {
            id: stateData.user.id,
            firstName: stateData.firstName,
            lastName: stateData.lastName,
            emailAddress: stateData.user.emailAddress,
            customFields: customFields,
            workspaceId: stateData.workspaceId
          }
          return dispatch('lmsUserAccountUpdateDetails', userData)
        }

        const lmsUserAccountUpdateEmail = () => {
          const userData = {
            id: stateData.user.id,
            emailAddress: stateData.emailAddress
          }
          return dispatch('lmsUserAccountUpdateEmail', userData)
        }

        const lmsSuperadminOrgUnitMoveUsers = () => {
          const userData = {
            targetUnitId: stateData.unit,
            workspaceId: stateData.workspaceId,
            profiles: [{
              originUnitId: stateDefaultData.unit,
              id: stateData.id
            }]
          }
          return dispatch('lmsSuperadminOrgUnitMoveUsers', userData)
        }

        const lmsSuperadminOrgUnitRemoveUsers = () => {
          const userData = {
            unitId: stateDefaultData.unit,
            customerId: stateData.customerId,
            workspaceId: stateData.workspaceId,
            userProfileId: stateData.id
          }
          return dispatch('lmsSuperadminOrgUnitRemoveUsers', userData)
        }

        const ebsEnrolmentAssignSuggestedCourses = () => {
          const userData = {
            catalogItems: stateData.combineSuggestedCourses.filter(obj => obj.selected).map(obj => obj.id),
            learner: stateData.user.id,
            workspaceId: stateData.workspaceId
          }
          return dispatch('ebsEnrolmentAssignSuggestedCourses', userData)
        }

        const onResolve = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Profile update',
            message: `Profile has been successfully updated.`,
            status: 'success'
          })
          if (redirect) {
            router.push({ name: 'lms-superadmin-workspace-edit-users', params: { id: workspaceId } })
          }
        }
        const onReject = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Profile update',
            message: `Profile hasn't been updated.`,
            status: 'error'
          })
        }

        return axios({
          method: 'patch',
          url: `${api.sso}/v2/user-profiles`,
          data: data
        }).then(response => {
          let execFunctionsArr = []

          const unitId = data.unit
          const profileId = response.data.profileId

          if (isUnitManager && unitId && profileId) {
            dispatch('lmsSuperadminOrgUnitAddManager', { unitId, profileId })
          }

          if (
            stateData.firstName !== stateDefaultData.firstName ||
          stateData.lastName !== stateDefaultData.lastName ||
          customFields.length
          ) {
            execFunctionsArr.push(lmsUserAccountUpdateDetails)
          }

          if (stateData.emailAddress !== stateDefaultData.emailAddress) {
            execFunctionsArr.push(lmsUserAccountUpdateEmail)
          }

          if (stateData.unit !== stateDefaultData.unit) {
            if (stateData.unit) {
              execFunctionsArr.push(lmsSuperadminOrgUnitMoveUsers)
            } else {
              if (stateDefaultData.unit) {
                execFunctionsArr.push(lmsSuperadminOrgUnitRemoveUsers)
              }
            }
          }

          if (stateData.combineSuggestedCourses.length && stateData.combineSuggestedCourses.filter(obj => obj.selected).length) {
            execFunctionsArr.push(ebsEnrolmentAssignSuggestedCourses)
          }

          if (execFunctionsArr.length) {
            for (let index in execFunctionsArr) {
              execFunctionsArr[index]().then(response => {
                if (Number(index) === execFunctionsArr.length - 1) {
                  onResolve()
                  commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_DEFAULT')
                  resolve(response)
                }
              }).catch(error => {
                if (Number(index) === execFunctionsArr.length - 1) {
                  onReject()
                  reject(error)
                }
              })
            }
          } else {
            onResolve()
            commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_DEFAULT')
            resolve(response)
          }
        }).catch(error => {
          onReject()
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileAddToGroups (ctx, data) {
      return new Promise((resolve, reject) => {
        const profileId = data.profileId
        const groupIds = data.groupIds

        axios({
          method: 'post',
          url: `${api.sso}/v2/user-groups`,
          data: {
            profileId,
            groupIds
          }
        }).then(response => {
          if (response.status === '207' || response.status === 207) {
            reject(new Error())
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileRemoveFromGroups (ctx, data) {
      return new Promise((resolve, reject) => {
        const profileId = data.profileId
        const groupIds = data.groupIds

        axios({
          method: 'post',
          url: `${api.sso}/v2/user-groups-remove`,
          data: {
            profileId,
            groupIds
          }
        }).then(response => {
          if (response.status === '207' || response.status === 207) {
            reject(new Error())
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileCancelInvitation ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const invitationId = data.id
        const redirect = data.redirect !== undefined ? data.redirect : true
        const workspaceId = JSON.parse(JSON.stringify(getters.getLmsSuperadminWorkspaceId))
        return axios({
          method: 'post',
          url: `${api.sso}/v2/cancel-invitation/${invitationId}`
        }).then(function (response) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation cancelled',
            message: ``,
            status: 'success'
          })
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_CLEAR')
          commit('LMS_SUPERADMIN_WORKSPACE_USERS_CLEAR')
          if (redirect) {
            router.push({ name: 'lms-superadmin-workspace-edit-users', params: { id: workspaceId } })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation not cancelled',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileResendInvitation ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const invitationId = data.id
        const redirect = data.redirect !== undefined ? data.redirect : true
        const workspaceId = JSON.parse(JSON.stringify(getters.getLmsSuperadminWorkspaceId))
        return axios({
          method: 'post',
          url: `${api.sso}/v2/resend-invitation/${invitationId}`
        }).then(function (response) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation sent',
            message: ``,
            status: 'success'
          })
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_CLEAR')
          commit('LMS_SUPERADMIN_WORKSPACE_USERS_CLEAR')
          if (redirect) {
            router.push({ name: 'lms-superadmin-workspace-edit-users', params: { id: workspaceId } })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Invitation not sent',
            message: ``,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminWorkspaceUserProfileCheckEmail ({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        const authUserCheckExistsSSO = dispatch('authUserCheckExistsSSO', data)
        const invitedUser = state.data.invitedUser
        if (invitedUser) {
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_FIRSTNAME', '')
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_LASTNAME', '')
        }

        authUserCheckExistsSSO.then(response => {
          if (response === null) reject(new Error('null'))
          const firstName = response.firstName || ''
          const lastName = response.lastName || ''
          const id = response.id || ''
          const profiles = response.profiles || ''
          const invitations = response.invitations || ''
          const profileWithUnit = profiles.find(obj => obj.customerId === state.data.customerId && obj.unit)
          const selectedUnit = profileWithUnit ? profileWithUnit.unit : null
          const customerRoles = response.extendedCustomerRoles || []

          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_FIRSTNAME', firstName)
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_LASTNAME', lastName)
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITEDUSER', id)
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_PROFILES', profiles)
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITATIONS', invitations)
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERROLES', customerRoles)
          if (selectedUnit) {
            commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_UNIT', selectedUnit)
          } else {
            commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_PROFILES', profiles || [])
          }
          resolve()
        }).catch(error => {
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITEDUSER', '')
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_INVITATIONS', [])
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_PROFILES', [])
          commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERROLES', [])
          reject(error)
        })
      })
    },
    //
    lmsSuperadminWorkspaceUserProfileSetId ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ID', value)
    },
    lmsSuperadminWorkspaceUserProfileSetEmail ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_EMAIL', value)
    },
    lmsSuperadminWorkspaceUserProfileSetFirstName ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_FIRSTNAME', value)
    },
    lmsSuperadminWorkspaceUserProfileSetLastName ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_LASTNAME', value)
    },
    lmsSuperadminWorkspaceUserProfileSetRoles ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ROLES', value)
    },
    lmsSuperadminWorkspaceUserProfileSetManagerOrgUnits ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_MANAGERORGUNITS', value)
    },
    lmsSuperadminWorkspaceUserProfileSetToWorkspace ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_TOWORKSPACE', value)
    },
    lmsSuperadminWorkspaceUserProfileSetWorkspaceId ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACEID', value)
    },
    lmsSuperadminWorkspaceUserProfileSetWorkspaceName ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACENAME', value)
    },
    lmsSuperadminWorkspaceUserProfileSetCustomerId ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERID', value)
    },
    lmsSuperadminWorkspaceUserProfileSetCustomerName ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMERNAME', value)
    },
    lmsSuperadminWorkspaceUserProfileSetCustomField ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_CUSTOMFIELD', value)
    },
    lmsSuperadminWorkspaceUserProfileSetStatus ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_STATUS', value)
    },
    lmsSuperadminWorkspaceUserProfileSetExpireDate ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_EXPIREDATE', value)
    },
    lmsSuperadminWorkspaceUserProfileSetUnit ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_UNIT', value)
    },
    lmsSuperadminWorkspaceUserProfileSetIsUnitManager ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_ISUNITMANAGER', value)
    },
    lmsSuperadminWorkspaceUserProfileSetWorkspaceGroups ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_WORKSPACEGROUPS', value)
    },
    lmsSuperadminWorkspaceUserProfileSetCombineSuggestedCourses ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_COMBINE_SUGGESTEDCOURSES', value)
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_COLLECT_SUGGESTEDCOURSES')
    },
    lmsSuperadminWorkspaceUserProfileSetCombineSuggestedCoursesSelected ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_COMBINE_SUGGESTEDCOURSES_SELECTED', value)
    },
    lmsSuperadminWorkspaceUserProfileSetSuggestedCourses ({ commit }, value) {
      commit('LMS_SUPERADMIN_WORKSPACE_USER_PROFILE_SET_SUGGESTEDCOURSES', value)
    }
  }
}

export default lmsSuperadminWorkspaceUserProfile
