<template>
    <div :class="[
    'component',
    'component__list',
    !paginationHasPages ? 'component__list--no-pagination' : 'component__list--has-pagination',
    `component__list--${listView}`
    ]">
        <div
            v-if="filtersActive && !(hideFiltersForCalendarView && listView === 'calendar')"
            :class="[
                'component__list_filters'
            ]"
        >
            <base-filter
                ref="basefilter"
                :actions-active="filterActionsActive && !actionsDisabled"
                :actions-primary-button-active="filterActionsPrimaryButtonActive"
                :actions-primary-button-text="filterActionsPrimaryButtonText"
                :actions-primary-button-disabled="filterActionsPrimaryButtonDisabled"
                :actions-secondary-button-active="filterActionsSecondaryButtonActive"
                :actions-secondary-button-text="filterActionsSecondaryButtonText"
                :details-active="filterDetailsActive && !requestPending"
                :details-items-number="filterSearchQueryable ? rawItems.length : (outputItems !== null ? (outputItems.length ? outputItems.length : (rawItems ? rawItems.length : 0)) : 0)"
                :details-items-number-of="rawPagination ? paginationTotalResults : rawItems.length"
                :details-items-name="itemName"
                :filter-search-default="query"
                :filter-data="rawItems"
                :filter-page-size="pageSize"
                :filter-default-page-size="defaultPageSize"
                :filter-list-tooltip-active="filterListTooltipActive"
                :list-active="Boolean(listViewItems) && Boolean(listViewItems.length)"
                :list-items="listViewItems"
                :list-view="listView"
                :search-active="filterSearchActive"
                :search-placeholder="filterSearchPlaceholder || `Search ${itemName.constructor === Array ? itemName[0] : itemName}`"
                :search-queryable="filterSearchQueryable"
                :search-by-field="filterSearchByField"
                :sort-active="filterSortActive"
                :select-active="filterSelectActive"
                :select-items="filterSelectItemsOutput"
                :select-button-animate-loading-force="requestPending"
                :select-button-animate-loading-name="`filter-select-${singularItemName}`"
                :note-active="filterNoteActive"
                :note-text="filterNoteText"
                :overlay="filterOverlay"
                @emit-actions-primary="handleEmit({ message: 'emit-actions-primary' })"
                @emit-actions-secondary="handleEmit({ message: 'emit-actions-secondary' })"
                @emit-filter-result="setFilterResult"
                @emit-filter-query="setFilterQuery"
                @emit-filter-search="setFilterSearch"
                @emit-filter-select="setFilterSelect"
                @emit-filter-select-extended="setFilterSelectExtended"
                @emit-filter-page-size="setFilterPageSize"
                @emit-filter-select-button="getItems({ forceFirstPage: true })"
                @emit-switch-list-view="switchListView"
            />
        </div>
        <div class="component__list_main">
            <template v-if="$slots.beforemain">
                <slot name="beforemain"></slot>
            </template>
            <div class="container">
                <div class="columns is-block-mobile is-variable">
                    <template v-if="$slots.left">
                        <div class="column is-narrow">
                            <slot name="left"></slot>
                        </div>
                    </template>
                    <div class="column">
                        <keep-alive>
                            <template v-if="requestPending || forceLoading">
                                <component-loading/>
                            </template>
                            <template v-else>
                                <template v-if="listView === 'grid'">
                                    <template v-if="rawItems.length && outputItems">
                                        <component
                                            :is="componentGrid"
                                            :items="outputItems ? (outputItems.length ? outputItems : rawItems) : []"
                                            :key="rerenderIndex"
                                            @emit="handleEmit"
                                            @emit-remove="openNotifyRemoveItem"
                                            @emit-perform-get-items="getItems"
                                        />
                                    </template>
                                    <template v-else>
                                        <component-empty-state
                                            :item="pluralItemName || singularItemName"
                                            :title="emptyStateTitleOutput"
                                            :content="emptyStateContent"
                                            :submit-button-active="emptyStateButtonActive"
                                            :submit-button-text="emptyStateButtonText"
                                            @emit-empty-state-submit="handleEmit({ message: 'emit-empty-state-submit' })"
                                        />
                                    </template>
                                </template>
                                <template v-if="listView === 'table'">
                                    <component
                                        :key="rerenderIndex"
                                        :is="componentTable"
                                        :name="componentTableName"
                                        :items="outputItems ? (outputItems.length ? outputItems : rawItems) : []"
                                        :labels="componentTableLabels"
                                        :sort-queryable="paginationHasPages"
                                        :component-table-allow-single="componentTableAllowSingle"
                                        :eventbus-emit-name="eventbusEmitName"
                                        :empty-state-title="emptyStateTitleOutput"
                                        :empty-state-content="emptyStateContent"
                                        :empty-state-button-active="emptyStateButtonActive"
                                        :empty-state-button-text="emptyStateButtonText"
                                        :component-table-checkbox="componentTableCheckbox"
                                        :component-table-add-active="componentTableAddActive"
                                        :actions-disabled="actionsDisabled"
                                        :selected="selected"
                                        :disallow="disallow"
                                        @emit="handleEmit"
                                        @emit-perform-get-items="getItems"
                                        @emit-sort="setSort"
                                        @emit-remove="openNotifyRemoveItem"
                                        @emit-check="handleEmitCheck(...arguments)"
                                        @emit-empty-state-submit="handleEmit({ message: 'emit-empty-state-submit' })"
                                    />
                                </template>
                                <template v-if="listView === 'calendar'">
                                    <component
                                        :key="rerenderIndex"
                                        :is="componentCalendar"
                                        :list-view="listView"
                                        @emit-switch-view="switchListView"
                                    />
                                </template>
                            </template>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="(getPaginationGetter || paginationActive) && paginationHasPages && listView !== 'calendar'">
            <div class="component__list_pagination">
                <component-pagination
                    :pagination="rawPagination"
                    @emit-page="setFilterPage"
                />
            </div>
        </template>
        <template v-if="removeItemDispatch">
            <pop-notify
                ref="popnotifyremove"
                :class="'pop__notify--archive'"
                :title-text="`${this.$t('shared.components.notify.archive')} ${singularItemName}`"
                :content-text="`${this.$t('shared.components.notify.contentTextArchive')} ${singularItemName}?`"
                :cancel-button-text="$t('shared.components.buttons.cancel')"
                :submit-button-text="`${this.$t('shared.components.notify.archive')} ${singularItemName}`"
                :submit-button-danger="true"
                @emit-submit="removeItem"
            />
        </template>
    </div>
</template>

<script>
import BaseFilter from '@/components/default/filters/BaseFilter'
import { User } from '@/User'
import ComponentEmptyState from '@/components/default/shared/ComponentEmptyState'
import ComponentPagination from '@/components/default/shared/ComponentPagination'
import ComponentLoading from '@/components/default/shared/ComponentLoading'
import { TablePreferences } from '@/TablePreferences'
import { isMobileView } from '@/functions'
import { Storage } from '@/Storage'

export default {
  name: 'component-list',
  components: {
    PopNotify: () => import('@/components/default/shared/PopNotify'),
    ComponentLoading,
    ComponentPagination,
    ComponentEmptyState,
    BaseFilter
  },
  data () {
    return {
      listView: '',
      outputItems: [],
      user: new User(),
      rerenderIndex: 0,
      requestPending: false,
      // data parameters for request
      page: 1,
      pageSize: 20,
      query: '',
      search: '',
      sortBy: '',
      sortDirection: '',
      sortQueryable: false,
      select: [],
      selectExtended: [],
      preventWatchers: true,
      forceLoading: false,
      routePath: ''
    }
  },
  props: {
    hideFiltersForCalendarView: {
      type: Boolean,
      default: false
    },
    disallow: {
      type: [Array, String, Number]
    },
    actionsDisabled: {
      type: Boolean,
      default: false
    },
    filterListTooltipActive: {
      type: Boolean
    },
    listViewItems: {
      type: Array
    },
    selected: {
      type: [Array, Object]
    },
    filtersActive: {
      type: Boolean,
      default: true
    },
    items: {
      type: [Array, String],
      default: () => []
    },
    tablePreferencesActive: {
      type: Boolean,
      default: false
    },
    componentTableName: {
      type: String
    },
    componentTableAddActive: {
      type: Boolean,
      default: false
    },
    componentTableLabels: {
      type: [Array, Object],
      default: () => {
      }
    },
    componentTableAllowSingle: {
      type: Boolean,
      default: false
    },
    componentTableCheckbox: {
      type: Boolean,
      default: false
    },
    eventbusEmitName: {
      type: String,
      default: 'pop-table'
    },
    componentGrid: {
      type: Object
    },
    componentTable: {
      type: Object
    },
    componentCalendar: {
      type: Object
    },
    itemName: {
      type: [String, Array],
      required: true
    },
    emptyStateTitle: {
      type: String
    },
    emptyStateContent: {
      type: String
    },
    emptyStateButtonActive: {
      type: Boolean,
      default: false
    },
    emptyStateButtonText: {
      type: String,
      default: 'Submit'
    },
    filterNoteActive: {
      type: Boolean,
      default: false
    },
    filterNoteText: {
      type: String
    },
    filterOverlay: {
      type: Boolean,
      default: true
    },
    filterDetailsActive: {
      type: Boolean,
      default: true
    },
    filterActionsActive: {
      type: Boolean,
      default: false
    },
    filterActionsPrimaryButtonActive: {
      type: Boolean,
      default: true
    },
    filterActionsPrimaryButtonDisabled: {
      type: Boolean,
      default: false
    },
    filterActionsPrimaryButtonText: {
      type: String
    },
    filterActionsSecondaryButtonActive: {
      type: Boolean,
      default: false
    },
    filterActionsSecondaryButtonText: {
      type: String
    },
    filterSearchActive: {
      type: Boolean,
      default: true
    },
    filterSearchQueryable: {
      type: Boolean,
      default: false
    },
    filterSearchPlaceholder: {
      type: String
    },
    filterSearchByField: {
      type: [String, Array]
    },
    filterSortActive: {
      type: Boolean
    },
    filterSortDefault: {
      type: Object
    },
    filterSelectActive: {
      type: Boolean
    },
    filterSelectPrefix: {
      type: String,
      default: 'filters'
    },
    filterSelectPassToQueryUrl: {
      type: Boolean,
      default: true
    },
    filterSelectItems: {
      type: Array
    },
    //
    getPaginationGetter: {
      type: String
    },
    getItemsGetter: {
      type: String
    },
    getItemsDispatch: {
      type: String
    },
    getItemsDispatchParams: {
      type: [String, Object]
    },
    getItemsDispatchData: {
      type: [String, Array, Object]
    },
    preventGetItemsDispatch: {
      type: Boolean,
      default: false
    },
    removeItemDispatch: {
      type: String
    },
    getItemsDispatchTriggerName: {
      type: String,
      default: 'trigger-get-items'
    },
    forceLoadingTriggerName: {
      type: String,
      default: 'trigger-force-loading'
    },
    //
    paginationActive: {
      type: Boolean,
      default: true
    },
    defaultPageSize: {
      type: Number,
      default: 20
    },
    permanentSearchTerms: {
      type: Boolean,
      default: false
    },
    temporarySearchTerms: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    emptyStateTitleOutput () {
      return !this.query ? this.emptyStateTitle : undefined
    },
    rawItems () {
      return this.getItemsGetter ? this.$store.getters[this.getItemsGetter] : this.items
    },
    rawPagination () {
      return this.getPaginationGetter ? this.$store.getters[this.getPaginationGetter] : {
        page: this.page,
        pageSize: this.pageSize,
        totalResults: this.search ? (this.outputItems ? this.outputItems.length : 0) : (this.rawItems ? this.rawItems.length : 0)
      }
    },
    watchPageParams () {
      return this.page + this.pageSize + this.query
    },
    watchSortParams () {
      return this.sortBy + this.sortDirection
    },
    paginationHasPages () {
      return this.rawPagination ? this.rawPagination.pageSize < this.rawPagination.totalResults : undefined
    },
    paginationTotalResults () {
      return this.rawPagination ? this.rawPagination.totalResults : undefined
    },
    singularItemName () {
      return this.itemName.constructor === Array ? this.itemName[0] : this.itemName
    },
    pluralItemName () {
      return this.itemName.constructor === Array ? this.itemName[1] : ''
    },
    sessionStorageListSearchTerms () {
      return Storage.getSession('listSearchTerms') ? JSON.parse(Storage.getSession('listSearchTerms')) : undefined
    },
    localStorageListSearchTermsPermanent () {
      const localStorageListSearchTermsPermanent = Storage.getLocal('listSearchTermsPermanent') ? JSON.parse(Storage.getLocal('listSearchTermsPermanent')) : undefined

      if (!localStorageListSearchTermsPermanent) return

      const currentTableStorageValue = localStorageListSearchTermsPermanent.find(obj => obj.tableName === this.componentTableName)

      return currentTableStorageValue || undefined
    },
    filterSelectItemsOutput () {
      const filterSelectItems = this.filterSelectItems ? JSON.parse(JSON.stringify(this.filterSelectItems)) : []
      const storageListSearchTerms = this.permanentSearchTerms ? this.localStorageListSearchTermsPermanent : this.sessionStorageListSearchTerms

      if (storageListSearchTerms && storageListSearchTerms.tableName === this.componentTableName && (!storageListSearchTerms.workspaceId || storageListSearchTerms.workspaceId === this.user.workspaceId) && storageListSearchTerms.terms.selectExtended) {
        for (const [selectKey, selectValue] of Object.entries(storageListSearchTerms.terms.selectExtended)) {
          if (filterSelectItems.findIndex(item => item.key === selectKey) > -1 && selectValue && selectValue.length && !this.$route.query[selectKey]) {
            filterSelectItems[filterSelectItems.findIndex(item => item.key === selectKey)].preselected = selectValue
          }
        }
      }

      return filterSelectItems
    }
  },
  watch: {
    rawItems () {
      this.outputItems = this.rawItems
    },
    watchPageParams () {
      if (!this.preventWatchers) {
        this.getItems()
        this.$emit('emit-dispatch')
      }
    },
    watchSortParams () {
      if (this.sortQueryable && (this.paginationHasPages || !this.rawItems.length) && !this.preventWatchers) {
        this.getItems()
        this.$emit('emit-dispatch')
      }
    },
    listView (value, oldValue) {
      if (oldValue === 'calendar' && !this.preventWatchers) {
        this.getItems()
      }
    },
    getItemsDispatch (value, oldValue) {
      if (!oldValue) {
        this.getItems()
      }
    }
  },
  methods: {
    setSearchTermsSessionStorage () {
      const tableSearchTermsValueObject = {
        tableName: this.componentTableName,
        routePath: this.routePath,
        terms: {
          page: this.page,
          pageSize: this.pageSize,
          query: this.query,
          search: this.search,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
          selectExtended: this.selectExtended
        }
      }

      Storage.setSession('listSearchTerms', JSON.stringify(tableSearchTermsValueObject))
    },
    setSearchTermsLocalStorage () {
      const localStorageListSearchTermsPermanentValue = Storage.getLocal('listSearchTermsPermanent') ? JSON.parse(Storage.getLocal('listSearchTermsPermanent')) : []
      const tableSearchTermsValueObject = {
        workspaceId: this.user.workspaceId,
        tableName: this.componentTableName,
        routePath: this.routePath,
        terms: {
          page: this.page,
          pageSize: this.pageSize,
          query: this.query,
          search: this.search,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
          selectExtended: this.selectExtended
        }
      }

      let currentTableStorageValueIndex = localStorageListSearchTermsPermanentValue.findIndex(obj => obj.tableName === this.componentTableName)

      if (!localStorageListSearchTermsPermanentValue || currentTableStorageValueIndex === -1) {
        localStorageListSearchTermsPermanentValue.push(tableSearchTermsValueObject)
      } else if (currentTableStorageValueIndex > -1) {
        localStorageListSearchTermsPermanentValue[currentTableStorageValueIndex] = tableSearchTermsValueObject
      }

      Storage.setLocal('listSearchTermsPermanent', JSON.stringify(localStorageListSearchTermsPermanentValue))
    },
    handleEmit (data) {
      const message = data.message
      const value = data.value
      this.$emit(message, value)
    },
    handleEmitCheck (data) {
      this.$emit('emit-check', data)
    },
    openNotifyRemoveItem (value) {
      this.$refs.popnotifyremove.openNotify()
      this.$refs.popnotifyremove.setStoreValue(value)
    },
    removeItem () {
      const value = this.$refs.popnotifyremove.storeValue
      this.$store.dispatch(this.removeItemDispatch, value).then(() => {
        this.getItems()
      }).catch(() => {
        this.$store.dispatch('defaultNotificationToastsAdd', {
          title: 'Achievement remove',
          message: `Something went wrong while removing achievement. Try again or contact an administrator.`,
          status: 'error'
        })
      })
    },
    createItem () {
      this.$emit('emit-create')
    },
    setFilterResult (data) {
      this.outputItems = data
    },
    setFilterSearch (value) {
      this.search = value
      this.rerenderIndex++
    },
    setFilterQuery (value) {
      if (value === this.query) return

      this.setFilterPage(1)
      this.query = value
      this.$refs.basefilter.setFilterSearch(value)
      this.rerenderIndex++
    },
    setSort (data) {
      const oldSortQueryable = this.sortQueryable
      const newSortBy = data.sortBy
      const newSortDirection = data.sortDirection
      const newSortQueryable = data.sortQueryable

      this.sortBy = newSortBy
      this.sortDirection = newSortDirection
      this.sortQueryable = oldSortQueryable !== newSortQueryable ? newSortQueryable : oldSortQueryable

      if (this.tablePreferencesActive) {
        TablePreferences.setTablePreferences({ name: this.componentTableName, sortBy: newSortBy, sortDirection: newSortDirection })
      }
    },
    setFilterPageSize (value) {
      this.page = 1
      this.pageSize = value
    },
    setFilterSelect (value) {
      this.select = value
    },
    setFilterSelectExtended (value) {
      this.selectExtended = value
    },
    setFilterPage (value) {
      this.page = value

      if (!this.getPaginationGetter) {
        this.setOutputItemsByPage(value)
      }

      this.rerenderIndex++
    },
    setOutputItemsByPage (value) {
      const page = value
      const pageSize = this.pageSize
      const items = this.rawItems
      const sliceStart = ((page * pageSize) - pageSize)
      const sliceEnd = (page * pageSize)

      let selectedItems = items.slice(sliceStart, sliceEnd)

      this.outputItems = selectedItems
    },
    switchListView (value) {
      this.listView = value
      this.rerenderIndex++
      if (this.tablePreferencesActive && this.listView !== 'calendar') {
        TablePreferences.setTablePreferences({ name: this.componentTableName, listView: value })
      }
      this.$emit('emit-switch-view', value)
    },
    getItems (data) {
      if (!this.getItemsDispatch) return

      this.setFilterResult([])
      this.requestPending = true

      const forceFirstPage = !!(data && data.forceFirstPage)
      const requestData = this.getItemsDispatchData
      const page = forceFirstPage ? 1 : this.page
      const pageSize = this.pageSize
      const query = encodeURIComponent(this.query)
      const sortBy = this.sortBy
      const sortDirection = this.sortDirection
      const select = this.select
      const customParams = this.getItemsDispatchParams
      const filterSelectPrefix = this.filterSelectPrefix
      const passFiltersToQueryUrl = this.filterSelectPassToQueryUrl

      let querySelectObj
      let querySelectStr = ''
      let querySelectKeys
      let queryParamsObj
      let queryParamsKeys

      let queryUrlParams = ''

      querySelectObj = select
      querySelectKeys = Object.keys(querySelectObj)
      if (querySelectKeys.length) {
        for (let index in querySelectKeys) {
          const queryKey = querySelectKeys[index]
          const queryData = querySelectObj[queryKey]
          if (queryData) {
            querySelectStr += index > 0 ? '&' : ''
            if (filterSelectPrefix) {
              querySelectStr += `${filterSelectPrefix}[` + queryKey + ']=' + (queryData.constructor === Array ? queryData.join() : queryData)
            } else {
              querySelectStr += queryKey + `=` + (queryData.constructor === Array ? queryData.join() : queryData)
            }
          }
        }
      }

      queryParamsObj = {
        page: page,
        pageSize: pageSize,
        filter: query,
        sortBy: sortBy,
        sortDirection: sortDirection,
        filters: querySelectStr
      }
      queryParamsKeys = Object.keys(queryParamsObj)

      if (queryParamsKeys.length) {
        for (let index in queryParamsKeys) {
          const queryKey = queryParamsKeys[index]
          const queryData = queryParamsObj[queryKey]
          if (queryData) {
            if (queryKey === 'filters') {
              if (!passFiltersToQueryUrl) continue
              queryUrlParams += Number(index) === 0 ? '?' : '&'
              queryUrlParams += queryParamsObj[queryKey]
            } else {
              queryUrlParams += Number(index) === 0 ? '?' : '&'
              queryUrlParams += queryKey + '=' + queryParamsObj[queryKey]
            }
          }
        }
      }

      if (customParams) {
        queryUrlParams += customParams
      }

      this.$store.dispatch(this.getItemsDispatch, { queryUrlParams, querySelectObj, customParams, requestData }).finally(() => {
        if (forceFirstPage) {
          this.page = 1
        }
        this.requestPending = false
        this.forceLoading = false
        this.$emit('emit-request-finally')
        this.$eventBus.$emit(`stop-filter-select-${this.singularItemName}`)
        this.rerenderIndex++
      }).catch(() => {})
    },
    setForceLoading () {
      this.forceLoading = true
    }
  },
  created () {
    const storageListSearchTerms = this.permanentSearchTerms ? this.localStorageListSearchTermsPermanent : this.sessionStorageListSearchTerms

    if (this.filterSortDefault) {
      this.setSort(this.filterSortDefault)
    }

    if (this.tablePreferencesActive) {
      const tablePreferences = TablePreferences.getTablePreferences(this.componentTableName)
      const tablePreferencesListView = tablePreferences ? tablePreferences.listView : ''
      const tablePreferencesSortBy = tablePreferences ? tablePreferences.sortBy : ''
      const tablePreferencesSortDirection = tablePreferences ? tablePreferences.sortDirection : ''

      if (tablePreferences) {
        if (tablePreferencesListView && tablePreferencesListView !== 'calendar') {
          this.listView = tablePreferencesListView
          this.$emit('emit-switch-view', tablePreferencesListView)
        } else {
          this.listView = 'table'
          this.$emit('emit-switch-view', 'table')
        }
        if (tablePreferencesSortBy) {
          this.sortBy = tablePreferencesSortBy
        }
        if (tablePreferencesSortDirection) {
          this.sortDirection = tablePreferencesSortDirection
        }
      } else {
        this.listView = 'table'
      }
    } else {
      this.listView = 'table'
    }

    if (storageListSearchTerms && storageListSearchTerms.tableName === this.componentTableName && (!storageListSearchTerms.workspaceId || storageListSearchTerms.workspaceId === this.user.workspaceId)) {
      this.page = storageListSearchTerms.terms.page
      this.pageSize = storageListSearchTerms.terms.pageSize
      this.query = storageListSearchTerms.terms.query
      this.search = storageListSearchTerms.terms.search
      this.sortBy = storageListSearchTerms.terms.sortBy
      this.sortDirection = storageListSearchTerms.terms.sortDirection
    }

    setTimeout(() => {
      this.preventWatchers = false
    }, 100)
  },
  mounted () {
    this.$eventBus.$on(this.getItemsDispatchTriggerName, this.getItems)
    this.$eventBus.$on(this.forceLoadingTriggerName, this.setForceLoading)

    this.routePath = this.$route.path

    const routeQueryListView = this.$route.query.listView
    if (routeQueryListView) {
      this.switchListView(routeQueryListView)
    }
    if (this.defaultPageSize && !this.pageSize) {
      this.pageSize = this.defaultPageSize
    }
    if (this.getItemsDispatch && !this.preventGetItemsDispatch) {
      this.getItems()
    } else {
      this.setFilterPage(1)
    }
    if (isMobileView() && this.componentGrid) {
      this.switchListView('grid')
    }
  },
  beforeDestroy () {
    if (!this.user.isInpersonated()) {
      if (this.permanentSearchTerms) {
        this.setSearchTermsLocalStorage()
      } else if (this.temporarySearchTerms) {
        this.setSearchTermsSessionStorage()
      }
    }

    this.$eventBus.$off(this.getItemsDispatchTriggerName)
    this.$eventBus.$off(this.forceLoadingTriggerName)
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/default/shared/_componentlist.scss";
</style>
