<template>
    <div :class="[
    'component',
    'component__orgunitsfilter_item',
    searchQuery ? (includedInSearchQuery || hasChildInSearchQuery ? 'component__orgunitsfilter_item--insearchquery' : 'component__orgunitsfilter_item--notinsearchquery') : '',
    multiple ? 'component__orgunitsfilter_item--multiple' : 'component__orgunitsfilter_item--single',
    ]">
        <div class="component__orgunitsfilter_item_top">
            <div class="columns is-variable is-vcentered is-1">
                <template v-if="multiple">
                    <div class="column is-narrow">
                        <form-checkbox
                            ref="input"
                            :label="''"
                            :key="name + index"
                            :name="name + index"
                            :value="checkboxValue"
                            :disabled="disabled || (allowed && allowed.length ? !allowed.includes(unitId) : false)"
                            @input="performEmitChecked"
                        ></form-checkbox>
                    </div>
                </template>
                <template v-if="unitChildrenOutput && unitChildrenOutput.length">
                    <div class="column is-narrow">
                        <div class="component__orgunitsfilter_item_top_switch">
                            <component-button
                                :name="`component-orgunitsfilter-item-top-switch-${index}`"
                                :icon="true"
                                :icon-name="isMainBoxActive ? 'chevron-down' : 'chevron-right'"
                                :icon-raw="true"
                                :theme="''"
                                @click="switchMainBoxActive"
                            ></component-button>
                        </div>
                    </div>
                </template>
                <div class="column">
                    <template v-if="!multiple">
                        <div class="component__orgunitsfilter_item_top_name_checkbox">
                            <form-checkbox
                                ref="input"
                                :label="''"
                                :name="`component-orgunitsfilter-item-top-checkbox-${index}`"
                                :value="checkboxValue"
                                :disabled="disabled || (allowed && allowed.length ? !allowed.includes(unitId) : false)"
                                :sublabel="unitName"
                                @input="performEmitChecked"
                            ></form-checkbox>
                        </div>
                    </template>
                    <template v-else>
                        <div class="component__orgunitsfilter_item_top_name">
                            <span>{{ unitName }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <template v-if="unitChildrenOutput && unitChildrenOutput.length && isMainBoxActive">
            <div class="component__orgunitsfilter_item_main">
                <component-org-units-filter-item
                    ref="childrenitems"
                    v-for="(unit, unitIndex) in unitChildrenOutput"
                    :key="unitIndex"
                    :disabled="disabledUnits && disabledUnits.length ? disabledUnits.includes(unit.id) : false"
                    :index="[...index, unitIndex]"
                    :item="unit"
                    :values="values"
                    :check-parent-if-children-selected="checkParentIfChildrenSelected"
                    :search-query="searchQuery"
                    :allowed="allowed"
                    :disabled-units="disabledUnits"
                    :multiple="multiple"
                    @emit-checked="handleEmitChecked"
                ></component-org-units-filter-item>
            </div>
        </template>
    </div>
</template>

<script>
import FormCheckbox from '@/components/default/forms/FormCheckbox'

export default {
  name: 'component-org-units-filter-item',
  data () {
    return {
      isMainBoxActive: true
    }
  },
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    FormCheckbox,
    ComponentOrgUnitsFilterItem: () => import('@/components/default/shared/ComponentOrgUnitsFilterItem')
  },
  props: {
    allowed: {
      type: Array,
      default: null
    },
    disabledUnits: {
      type: Array,
      default: null
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    index: {
      type: Array,
      default: () => []
    },
    name: {
      type: String
    },
    values: {
      type: Array,
      default: () => []
    },
    checkParentIfChildrenSelected: {
      type: Boolean,
      default: true
    },
    searchQuery: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    unitName () {
      return this.item.name
    },
    unitId () {
      return this.item.id
    },
    unitChildren () {
      return this.item.children
    },
    unitChildrenOutput () {
      const rawOrgUnits = this.unitChildren
      const allowedOrgUnits = this.allowed
      let orgUnitsOutput

      if (allowedOrgUnits && allowedOrgUnits.length) {
        orgUnitsOutput = rawOrgUnits.filter(fObj => {
          const orgUnit = fObj
          const orgUnitId = orgUnit.id
          let orgUnitChildrenIds = []

          const recursiveOrgUnitChildrenIdsLoop = (node, nodeIndex) => {
            for (let i in node.children) {
              let nodeItem = node.children[i]
              if (nodeItem && nodeItem.id) {
                orgUnitChildrenIds.push(nodeItem.id)
              }
              if (nodeItem && nodeItem.children) {
                recursiveOrgUnitChildrenIdsLoop(node.children[i], nodeIndex ? [...nodeIndex, i] : i)
              }
            }
          }
          recursiveOrgUnitChildrenIdsLoop(orgUnit)

          return allowedOrgUnits.find(obj => obj === orgUnitId || orgUnitChildrenIds.includes(obj))
        })
      } else {
        orgUnitsOutput = rawOrgUnits
      }

      return orgUnitsOutput
    },
    unitChildrenIds () {
      let idsArr = []

      const recursiveLoop = (data) => {
        for (let i in data) {
          idsArr.push(data[i].id)
          if (data[i].children) {
            recursiveLoop(data[i].children)
          }
        }
      }

      recursiveLoop(this.unitChildrenOutput)

      return idsArr
    },
    hasAllChildrenSelected () {
      const children = this.unitChildrenIds
      const selected = this.values
      let allSelected = true

      for (let child of children) {
        if (!selected.includes(child)) {
          allSelected = false
        }
      }

      return allSelected
    },
    checkboxValue () {
      return this.values.includes(this.unitId)
    },
    includedInSearchQuery () {
      return this.unitName ? this.unitName.toLowerCase().includes(this.searchQuery.toLowerCase()) : false
    },
    hasChildInSearchQuery () {
      let childInSearchQuery = false
      let unit = this.item

      if (!this.searchQuery) return false

      const recursiveThroughChildren = (node) => {
        if (node.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          childInSearchQuery = true
        } else if (node.children.length) {
          for (let index in node.children) {
            recursiveThroughChildren(node.children[index])
          }
        }
      }

      recursiveThroughChildren(unit)

      return childInSearchQuery
    }
  },
  methods: {
    switchMainBoxActive () {
      this.isMainBoxActive = !this.isMainBoxActive
    },
    performEmitChecked (data) {
      this.isMainBoxActive = true
      this.$nextTick(() => {
        if (this.allowed && this.allowed.length ? this.allowed.includes(this.unitId) : true) {
          this.$emit('emit-checked', { value: data, id: this.unitId, name: this.unitName })
        }
        if (this.checkParentIfChildrenSelected) {
          const childrenItems = this.$refs.childrenitems
          for (let index in childrenItems) {
            childrenItems[index].performEmitChecked(data)
          }
        }
      })
    },
    handleEmitChecked (data) {
      this.$emit('emit-checked', data)
    }
  },
  mounted () {
    this.$eventBus.$on('filter-select-all-org-units', () => this.performEmitChecked(true))
    this.$eventBus.$on('filter-unselect-all-org-units', () => this.performEmitChecked(false))
    if (this.index.length > 2) {
      this.isMainBoxActive = false
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('filter-select-all-org-units')
    this.$eventBus.$off('filter-unselect-all-org-units')
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentorgunitsfilteritem";
</style>
