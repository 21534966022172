<template>
    <div class="filter__select_menu">
        <component-button
            :theme="''"
            class="filter__select_menu_close"
            @click="$parent.switchMenu()"
            :icon="true"
            :icon-name="'x'"
            :icon-raw="true"
            :name="'filter-select-menu-close'">
        </component-button>
        <div class="filter__select_menu_label">{{filterSelectTitle}}</div>
        <div class="filter__select_menu_main">
            <div v-show="loading" class="filter__select_menu_main_loading">
                <component-loading/>
            </div>
            <div v-show="!loading" class="filter__select_menu_main_content">
                <template v-if="filterSelectType === 'checkbox'">
                    <div class="filter__select_menu_checkbox">
                        <form-checkbox-vertical
                            ref="formcheckbox"
                            :disabled="filterSelectDisabled"
                            :label="''"
                            :name="filterSelectTitle"
                            :items="filterSelectItems"
                            :preselected="filterSelectPreselected"
                            @emit-selected="emitSelected"
                            @emit-filter="emitFilter"
                        />
                    </div>
                </template>
                <template v-else-if="filterSelectType === 'multiselect'">
                    <div class="filter__select_menu_main_content_multiselect">
                        <form-select
                            ref="formselect"
                            :disabled="filterSelectDisabled"
                            :name="filterSelectTitle"
                            :label="''"
                            :items="filterSelectItems"
                            :multiple="true"
                            :get-pagination-getter="filterSelectGetPaginationGetter"
                            :get-items-getter="filterSelectGetItemsGetter"
                            :get-items-getter-mapped="filterSelectGetItemsGetterMapped"
                            :get-items-dispatch="filterSelectGetItemsDispatch"
                            :get-items-dispatch-data="filterSelectGetItemsDispatchData"
                            :get-items-dispatch-params="filterSelectGetItemsDispatchParams"
                            :item-name="filterSelectItemName"
                            :search-queryable="filterSelectSearchQueryable"
                            :table-labels="filterSelectTableLabels"
                            :preselected="filterSelectPreselected"
                            @emit-selected="emitSelected"
                            @emit-filter="emitFilter"
                        />
                    </div>
                </template>
                <template v-if="filterSelectType === 'checkbox_multiselect'">
                    <div class="filter__select_menu_main_content_checkbox">
                        <form-checkbox-vertical
                            ref="formcheckbox"
                            :disabled="filterSelectDisabled"
                            :label="''"
                            :name="filterSelectTitle"
                            :items="filterSelectItems"
                            :item-extra="`${this.$t(`filter.select.itemExtra`)} ${filterSelectTitle}`"
                            :preselected="filterSelectPreselected"
                            @emit-extra="setCheckboxExtra"
                            @emit-selected="emitSelected"
                            @emit-filter="emitFilter"
                        />
                        <form-select
                            v-if="checkboxExtra"
                            ref="formselectextra"
                            :disabled="filterSelectDisabled"
                            :name="filterSelectTitle"
                            :label="''"
                            :items="filterSelectItemsExtra"
                            :multiple="true"
                            :overlay="true"
                            :get-pagination-getter="filterSelectGetPaginationGetter"
                            :get-items-getter="filterSelectGetItemsGetter"
                            :get-items-getter-mapped="filterSelectGetItemsGetterMapped"
                            :get-items-dispatch="filterSelectGetItemsDispatch"
                            :get-items-dispatch-data="filterSelectGetItemsDispatchData"
                            :get-items-dispatch-params="filterSelectGetItemsDispatchParams"
                            :item-name="filterSelectItemName"
                            :search-queryable="filterSelectSearchQueryable"
                            :table-labels="filterSelectTableLabels"
                            :preselected="filterSelectPreselected"
                            @emit-selected="emitSelectedExtra"
                            @emit-filter="emitFilterExtra"
                        />
                    </div>
                </template>
                <template v-else-if="filterSelectType === 'date'">
                    <div class="filter__select_menu_main_content_dates">
                        <form-datebetween
                            ref="formdatebetween"
                            :disabled="filterSelectDisabled"
                            :name="filterSelectTitle"
                            :preselected="filterSelectPreselected"
                            :range-only="filterSelectRangeOnly"
                            :range-active="filterSelectRangeActive"
                            :range-items="filterSelectRangeItems"
                            @emit-selected="emitSelected"
                            @emit-date-from="emitDateFrom"
                            @emit-date-to="emitDateTo"
                            @emit-range="emitRange"
                            @emit-filter="emitFilter"
                        />
                    </div>
                </template>
                <template v-if="filterSelectType === 'org-units'">
                    <div class="filter__select_menu_orgunits">
                        <component-org-units-filter
                            ref="formorgunits"
                            :disabled="filterSelectDisabled"
                            :preselected="filterSelectPreselected"
                            :allowed="filterSelectAllowed"
                            @emit-selected="emitSelected"
                            @emit-filter="emitFilter"
                        ></component-org-units-filter>
                    </div>
                </template>
            </div>
            <template v-if="!filterSelectPreventClear">
                <div class="filter__select_menu_main_actions">
                    <div class="columns is-variable">
                        <div class="column"></div>
                        <div class="column is-narrow">
                            <component-button
                                :theme="'default'"
                                :border="true"
                                :name="'filter-select-menu-actions-reset'"
                                @click="reset"
                            >Clear
                            </component-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import FormDatebetween from '@/components/default/forms/FormDateBetween'
import FormCheckboxVertical from '@/components/default/forms/FormCheckboxVertical'
import ComponentLoading from '@/components/default/shared/ComponentLoading'
import ComponentOrgUnitsFilter from '@/components/default/shared/ComponentOrgUnitsFilter'

export default {
  name: 'filter-select-menu',
  data () {
    return {
      checkboxExtra: false
    }
  },
  components: {
    ComponentOrgUnitsFilter,
    ComponentLoading,
    FormCheckboxVertical,
    FormDatebetween,
    FormSelect: () => import('@/components/default/forms/FormSelect'),
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    filterSelectRangeActive: {
      type: Boolean
    },
    filterSelectDisabled: {
      type: Boolean
    },
    filterSelectRangeOnly: {
      type: Boolean
    },
    filterSelectRangeItems: {
      type: Array
    },
    filterSelectRangeValue: {
      type: String
    },
    filterSelectType: {
      type: String
    },
    filterSelectTitle: {
      type: String
    },
    filterSelectPreselected: {
      type: [Object, String, Array]
    },
    filterSelectAllowed: {
      type: [Object, String, Array]
    },
    filterSelectValue: {
      type: [Object, String, Array]
    },
    filterSelectItems: {
      type: [Array, Object, String]
    },
    filterSelectItemsExtra: {
      type: [Array, Object]
    },
    filterSelectValueExtra: {
      type: [Object, String, Array]
    },
    filterSelectGetPaginationGetter: {
      type: String
    },
    filterSelectGetItemsGetter: {
      type: String
    },
    filterSelectGetItemsGetterMapped: {
      type: String
    },
    filterSelectGetItemsDispatch: {
      type: String
    },
    filterSelectGetItemsDispatchData: {
      type: [Array, Object, String]
    },
    filterSelectGetItemsDispatchParams: {
      type: String
    },
    filterSelectItemName: {
      type: [Array, Object]
    },
    filterSelectSearchQueryable: {
      type: Boolean
    },
    filterSelectTableLabels: {
      type: [Array, Object]
    },
    filterSelectPreventClear: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    setCheckboxExtra (data) {
      this.checkboxExtra = data
    },
    //
    emitSelected (data) {
      this.$emit('emit-selected', data)
    },
    emitSelectedExtra (data) {
      this.$emit('emit-selected-extra', data)
    },
    emitFilter (data) {
      this.$emit('emit-filter', data)
    },
    emitFilterExtra (data) {
      this.$emit('emit-filter-extra', data)
    },
    emitFilterCustom (data) {
      this.$emit('emit-filter-custom', data)
    },
    emitDateFrom (data) {
      this.$emit('emit-date-from', data)
    },
    emitDateTo (data) {
      this.$emit('emit-date-to', data)
    },
    emitRange (data) {
      this.$emit('emit-range', data)
    },
    //
    reset () {
      if (this.filterSelectType === 'checkbox') {
        this.$refs.formcheckbox.clearData()
      } else if (this.filterSelectType === 'multiselect') {
        this.$refs.formselect.clearData()
      } else if (this.filterSelectType === 'checkbox_multiselect') {
        this.$refs.formcheckbox.clearData()
        if (this.$refs.formselectextra) {
          this.$refs.formselectextra.clearData()
        }
      } else if (this.filterSelectType === 'date') {
        this.$refs.formdatebetween.clearData()
      } else if (this.filterSelectType === 'org-units') {
        this.$refs.formorgunits.clearData()
      }
    },
    submit () {
      this.$emit('emit-submit')
    }
  },
  watch: {
    checkboxExtra (value) {
      if (!value) {
        this.emitSelectedExtra()
        this.emitFilterExtra()
      }
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/ebs/filters/filterselectmenu";
</style>
