<template>
    <div :class="[
        'component',
        'component__orgunitsfilter',
        multiple ? 'component__orgunitsfilter--multiple' : 'component__orgunitsfilter--single',
    ]">
        <div class="component__orgunitsfilter_main">
            <div class="component__orgunitsfilter_main_structure">
                <template v-if="orgUnitsOutput.length">
                    <component-org-units-filter-item
                        :disabled="disabledUnits && disabledUnits.length ? disabledUnits.includes(unit.id) : false"
                        v-show="unit.id"
                        v-for="(unit, unitIndex) in orgUnitsOutput"
                        :key="name + unitIndex"
                        :name="name + unitIndex"
                        :index="[unitIndex]"
                        :item="unit"
                        :values="valueOutput"
                        :search-query="baseSearchQuery || searchQuery"
                        :allowed="allowed"
                        :disabled-units="disabledUnits"
                        :multiple="multiple"
                        :check-parent-if-children-selected="checkParentIfChildrenSelected"
                        @emit-checked="handleCheckedUnit"
                    ></component-org-units-filter-item>
                </template>
                <template v-else>
                    <template v-if="requestPending">
                        <component-loading :name="'org-units-filter-loader'" :size="'small'"></component-loading>
                    </template>
                    <template v-else>
                        <component-text :size="'small'">Workspace doesn't have the organisation unit structure yet.</component-text>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentOrgUnitsFilterItem from '@/components/default/shared/ComponentOrgUnitsFilterItem'
import ComponentLoading from '@/components/default/shared/ComponentLoading'

export default {
  name: 'component-org-units-filter',
  components: {
    ComponentLoading,
    ComponentText: () => import('@/components/default/shared/ComponentText'),
    ComponentOrgUnitsFilterItem
  },
  data () {
    return {
      selectedOrgUnits: [],
      requestPending: false,
      searchValue: '',
      baseSearchQuery: ''
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    allowed: {
      type: Array
    },
    disabledUnits: {
      type: Array
    },
    items: {
      type: Array,
      default: () => []
    },
    preselected: {
      type: [Array, String],
      default: () => []
    },
    name: {
      type: String,
      default: 'org-units-filter'
    },
    label: {
      type: String,
      default: 'Organisation unit'
    },
    searchQuery: {
      type: String
    },
    searchActive: {
      type: Boolean,
      default: true
    },
    workspaceId: {
      type: String
    },
    actionActive: {
      type: Boolean,
      default: true
    },
    preventInitialize: {
      type: Boolean,
      default: false
    },
    preventGetOrgUnits: {
      type: Boolean,
      default: false
    },
    checkParentIfChildrenSelected: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    loggedInWorkspaceId () {
      return this.$store.getters.getAuthUserSecondStageTokenDataWorkspaceId
    },
    orgUnits () {
      return this.items.length ? this.items : this.$store.getters.getLmsSuperadminOrgUnitsChildren.filter(obj => obj.id)
    },
    orgUnitsMappedIdName () {
      return this.$store.getters.getLmsSuperadminOrgUnitsMappedIdName
    },
    orgUnitsLength () {
      return this.items.length ? this.items.length : this.orgUnitsMappedIdName.length
    },
    valueOutput () {
      return this.selectedOrgUnits.map(obj => obj.id)
    },
    orgUnitsOutput () {
      const rawOrgUnits = this.orgUnits
      const allowedOrgUnits = this.allowed
      let orgUnitsOutput

      if (allowedOrgUnits && allowedOrgUnits.length) {
        orgUnitsOutput = rawOrgUnits.filter(fObj => {
          const orgUnit = fObj
          const orgUnitId = orgUnit.id
          let orgUnitChildrenIds = []

          const recursiveOrgUnitChildrenIdsLoop = (node, nodeIndex) => {
            for (let i in node.children) {
              let nodeItem = node.children[i]
              if (nodeItem && nodeItem.id) {
                orgUnitChildrenIds.push(nodeItem.id)
              }
              if (nodeItem && nodeItem.children) {
                recursiveOrgUnitChildrenIdsLoop(node.children[i], nodeIndex ? [...nodeIndex, i] : i)
              }
            }
          }
          recursiveOrgUnitChildrenIdsLoop(orgUnit)

          return allowedOrgUnits.find(obj => obj === orgUnitId || orgUnitChildrenIds.includes(obj))
        })
      } else {
        orgUnitsOutput = rawOrgUnits
      }

      return orgUnitsOutput
    }
  },
  watch: {
    orgUnitsMappedIdName (value) {
      if (value.length && !this.preventInitialize) {
        this.initializePreselectedValue()
      }
    },
    workspaceId (value) {
      if (value) {
        this.getOrgUnits()
      }
    }
  },
  methods: {
    getOrgUnits () {
      const workspaceId = this.workspaceId || this.loggedInWorkspaceId
      if (!workspaceId) return
      this.requestPending = true
      this.$store.dispatch('lmsSuperadminOrgUnitsGet', workspaceId).finally(() => {
        this.requestPending = false
      })
    },
    handleCheckedUnit (data) {
      let selectedOrgUnits = JSON.parse(JSON.stringify(this.selectedOrgUnits))

      if (data) {
        if (data.constructor === String) {
          if (selectedOrgUnits.findIndex(obj => obj.id === data) === -1) {
            selectedOrgUnits.push({
              id: data,
              name: '',
              value: true
            })
          }
        } else {
          if (selectedOrgUnits.findIndex(obj => obj.id === data.id) === -1) {
            if (data.value || data.value === undefined) {
              if (this.multiple) {
                selectedOrgUnits.push(data)
              } else {
                selectedOrgUnits = [data]
              }
            }
          } else {
            if (!data.value) {
              const itemIndex = selectedOrgUnits.findIndex(obj => {
                return obj.id === data.id
              })
              selectedOrgUnits.splice(itemIndex, 1)
            }
          }
        }
      } else {
        selectedOrgUnits = []
      }

      if (selectedOrgUnits.length) {
        selectedOrgUnits = selectedOrgUnits.map(mObj => {
          const unitExtended = this.orgUnitsMappedIdName.find(fObj => fObj.id === mObj.id)
          const unitExtendedName = unitExtended ? unitExtended.name : ''

          return {
            id: mObj.id,
            name: mObj.name || unitExtendedName
          }
        })
      }

      this.selectedOrgUnits = selectedOrgUnits

      let str
      if (!selectedOrgUnits.length) {
        str = ''
      } else if (selectedOrgUnits.length === 1) {
        if (this.multiple) {
          str = `${this.label}: ${selectedOrgUnits[0].name ? selectedOrgUnits[0].name : selectedOrgUnits[0]}`
        } else {
          str = selectedOrgUnits[0].name
        }
      } else {
        str = `${this.label}: ${selectedOrgUnits[0].name ? selectedOrgUnits[0].name : selectedOrgUnits[0]}, ${selectedOrgUnits.length - 1} more...`
      }
      this.emitSelected(str)
      this.emitFilter(selectedOrgUnits)
    },
    emitSelected (data) {
      this.$emit('emit-selected', data)
    },
    emitFilter (data) {
      let dataValue
      if (this.multiple) {
        dataValue = data
      } else {
        dataValue = data[0]
      }
      this.$emit('emit-filter', dataValue)
    },
    clearData () {
      this.handleCheckedUnit()
    },
    initializePreselectedValue () {
      if (!this.preselected) return
      const selectedValues = this.preselected.constructor === String ? [this.preselected] : this.preselected
      if (selectedValues && selectedValues.length) {
        for (let value of selectedValues) {
          this.handleCheckedUnit(value)
        }
      }
    },
    handleSelectAllOrgUnits () {
      if (this.orgUnitsLength !== this.preselected.length) {
        this.$eventBus.$emit('filter-select-all-org-units')
      } else {
        this.$eventBus.$emit('filter-unselect-all-org-units')
      }
    }
  },
  created () {
    if (!this.items.length && !this.requestPending && !this.preventGetOrgUnits) {
      this.getOrgUnits()
    }
  }
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/componentorgunitsfilter";
</style>
