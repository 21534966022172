import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { toISODateUTC, toLocaleDateTime } from '@/functions'

const data = {
  data: {
    contacts: [],
    id: '',
    latestSubscriptions: '',
    longName: '',
    name: '',
    notes: '',
    status: 'active',
    services: [],
    subscriptions: [],
    workspaces: [],
    options: {
      hideEventsCatalogue: false,
      ccs: {
        open: false,
        state: '',
        allowedCustomers: []
      }
    }
  },
  default: {},
  custom: {
    requestLoaded: false
  }
}

const lmsSuperadminCustomer = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminCustomer: state => {
      return state.data
    },
    getLmsSuperadminCustomerId: state => {
      return state.data.id
    },
    getLmsSuperadminCustomerLongName: state => {
      return state.data.longName
    },
    getLmsSuperadminCustomerContacts: state => {
      return state.data.contacts
    },
    getLmsSuperadminCustomerName: state => {
      return state.data.name
    },
    getLmsSuperadminCustomerNotes: state => {
      return state.data.notes
    },
    getLmsSuperadminCustomerStatus: state => {
      return state.data.status
    },
    getLmsSuperadminCustomerService: state => key => {
      return state.data.services[key]
    },
    getLmsSuperadminCustomerServices: state => {
      return state.data.services
    },
    getLmsSuperadminCustomerServicesByKey: state => key => {
      const services = JSON.parse(JSON.stringify(state.data.services))
      return services[key]
    },
    getLmsSuperadminCustomerServicesModified: (state, getters) => {
      const latestServices = JSON.parse(JSON.stringify(getters.getLmsSuperadminCustomerLatestSubscriptions))
      let servicesArr = []


      const services = state.data.services
      for (let service in services) {
        if (service === 'DW') continue

        let renamedService

        switch (service) {
          case 'LMS':
            renamedService = 'LMS'
            break
          case 'EBS':
            renamedService = 'Events'
            break
          case 'CCS':
            renamedService = 'Course Creator'
            break
          case 'ELMS':
            renamedService = 'SCORM Connectors'
            break
          case 'SSO':
            renamedService = 'Single Sign-on'
            break
          case 'API':
            renamedService = 'API'
            break
        }

        let serviceObj = {
          name: renamedService,
          serviceKey: '',
          status: '',
          fromDate: '',
          toDate: ''
        }
        if (services.hasOwnProperty(service)) {
          if (latestServices[service]) {
            serviceObj.serviceKey = latestServices[service].serviceKey
            serviceObj.subscriptionId = latestServices[service].id
            serviceObj.status = latestServices[service].status
            serviceObj.fromDate = toLocaleDateTime(latestServices[service].fromDate)
            serviceObj.toDate = toLocaleDateTime(latestServices[service].toDate)
          } else {
            serviceObj.serviceKey = service
            serviceObj.status = 'disabled'
          }
        }
        servicesArr.push(serviceObj)
      }
      return servicesArr
    },
    getLmsSuperadminCustomerSubscriptions: state => {
      return state.data.subscriptions
    },
    getLmsSuperadminCustomerSubscriptionsNotCancelledDates: (state, getters) => key => {
      const subscriptions = getters.getLmsSuperadminCustomerSubscriptionsByKey(key)
      const subscriptionsFiltered = subscriptions.filter(obj => {
        return obj.status !== 'cancelled'
      })
      const subscriptionsFilteredMapped = subscriptionsFiltered.map(obj => {
        return {
          fromDate: toISODateUTC(obj.fromDate),
          toDate: toISODateUTC(obj.toDate)
        }
      })
      return subscriptionsFilteredMapped
    },
    getLmsSuperadminCustomerSubscriptionsByKey: state => key => {
      const subscriptions = JSON.parse(JSON.stringify(state.data.subscriptions))
      return subscriptions.filter(obj => {
        return obj.serviceKey === key
      })
    },
    getLmsSuperadminCustomerSubscriptionsActiveByKey: state => key => {
      const subscriptions = JSON.parse(JSON.stringify(state.data.subscriptions))
      return subscriptions.find(obj => (
        obj.serviceKey === key &&
        obj.status === 'active' &&
        (obj.fromDate === null || new Date(obj.fromDate) < new Date()) &&
        (obj.toDate === null || new Date(obj.toDate) > new Date())
      ))
    },
    getLmsSuperadminCustomerSubscriptionsInactiveByKey: state => key => {
      const subscriptions = JSON.parse(JSON.stringify(state.data.subscriptions))
      return subscriptions.find(obj => (
        obj.serviceKey === key &&
        obj.status === 'inactive' &&
        (new Date(obj.fromDate) > new Date())
      ))
    },
    getLmsSuperadminCustomerLatestSubscriptions: state => {
      return state.data.latestSubscriptions
    },
    getLmsSuperadminCustomerWorkspaces: state => {
      return state.data.workspaces
    },
    getLmsSuperadminCustomerWorkspacesMappedLabelValue: state => {
      const rawItems = state.data.workspaces
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: `${item.name} (${item.customerName})`,
          rawLabel: item.name,
          value: item.id,
          customerName: item.customerName,
          customerId: item.customerId
        }
      })
    },
    getLmsSuperadminCustomerWorkspacesMappedIdNameStatus: state => {
      const rawItems = state.data.workspaces
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          customer: item.customerName,
          status: item.status
        }
      })
    },
    getLmsSuperadminCustomerOptionsEBSHideEventsCatalogue: state => {
      return state.data.options.hideEventsCatalogue
    },
    getLmsSuperadminCustomerOptionsCCSOpen: state => {
      return state.data.options.ccs.open
    },
    getLmsSuperadminCustomerOptionsCCSState: state => {
      return state.data.options.ccs.state
    },
    getLmsSuperadminCustomerOptionsCCSAllowedCustomers: state => {
      const allowedCustomer = state.data && state.data.options && state.data.options.ccs && state.data.options.ccs.allowedCustomers ? state.data.options.ccs.allowedCustomers : []

      return allowedCustomer
    }
  },
  mutations: {
    LMS_SUPERADMIN_CUSTOMER_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      payloadData.options = payloadData.options || JSON.parse(JSON.stringify(data.data.options))
      payloadData.options.ccs = JSON.parse(JSON.stringify(data.data.options.ccs))

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    LMS_SUPERADMIN_CUSTOMER_GET_CCSSETTINGS (state, payload) {
      state.data.options.ccs.open = !!(payload.state === 'open' || payload.state === 'limited')
      state.data.options.ccs.state = payload.state
      state.data.options.ccs.allowedCustomers = payload.allowedCustomers && payload.allowedCustomers.length ? payload.allowedCustomers.map(obj => obj.id) : []
    },
    //
    LMS_SUPERADMIN_CUSTOMER_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    LMS_SUPERADMIN_CUSTOMER_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    LMS_SUPERADMIN_CUSTOMER_UPDATE_DEFAULT (state) {
      state.default = JSON.parse(JSON.stringify(state.data))
    },
    //
    LMS_SUPERADMIN_CUSTOMER_SET_NAME (state, payload) {
      state.data.name = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_LONGNAME (state, payload) {
      state.data.longName = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_NOTES (state, payload) {
      state.data.notes = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_STATUS (state, payload) {
      state.data.status = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_EBS_HIDEEVENTSCATALOGUE (state, payload) {
      state.data.options.hideEventsCatalogue = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_CCS_OPEN (state, payload) {
      state.data.options.ccs.open = payload
    },
    LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_CCS_ALLOWEDCUSTOMERS (state, payload) {
      const payloadMapped = payload && payload.length ? payload.map(obj => obj.value) : []

      state.data.options.ccs.allowedCustomers = payloadMapped
    },
    LMS_SUPERADMIN_CUSTOMER_REMOVE_CONTACTBYID (state, payload) {
      const contacts = state.data.contacts
      const contactId = payload
      const contactIndex = contacts.findIndex(obj => {
        return obj.id === contactId
      })
      state.data.contacts.splice(contactIndex, 1)
    },
    LMS_SUPERADMIN_CUSTOMER_SET_CUSTOM_REQUESTLOADED (state, payload) {
      state.custom.requestLoaded = payload
    }
  },
  actions: {
    lmsSuperadminCustomerGetSimple ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const customerId = data || getters.getAuthUserSecondStageTokenDataCustomerId

        commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/customers/${customerId}`
        }).then(response => {
          resolve(response.data)
        }).catch(customerError => {
          reject(customerError)
        })
      })
    },
    lmsSuperadminCustomerGet ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const customerId = data ? (data.requestData !== undefined ? data.requestData : data) : getters.getAuthUserSecondStageTokenDataCustomerId

        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/customers/${customerId}`
        }).then(customerResponse => {
          commit('LMS_SUPERADMIN_CUSTOMER_GET', customerResponse.data)
          dispatch('lmsSuperadminCustomerGetCCSSettings', customerId).finally(() => {
            resolve(customerResponse.data)
          }).catch(customerSettingsError => {
            reject(customerSettingsError)
          })
        }).catch(customerError => {
          reject(customerError)
        })
      })
    },
    lmsSuperadminCustomerGetCCSSettings ({ state, commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const isUserLoggedInToWorkspace = Boolean(getters.getAuthUserSecondStageTokenDataWorkspaceId)
        const customerId = data && data.customerId ? data.customerId : getters.getAuthUserSecondStageTokenDataCustomerId
        const force = data && data.force ? data.force : false
        const requestLoaded = state.custom.requestLoaded

        if ((!customerId || !isUserLoggedInToWorkspace || requestLoaded) && !force) {
          reject()
          return
        }

        axios({
          method: 'get',
          url: `${api.courseCatalog}/customer-settings/${customerId}`
        }).then(response => {
          commit('LMS_SUPERADMIN_CUSTOMER_GET_CCSSETTINGS', response.data)
          commit('LMS_SUPERADMIN_CUSTOMER_SET_CUSTOM_REQUESTLOADED', true)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    lmsSuperadminCustomerClear (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
    },
    lmsSuperadminCustomerReset (context) {
      context.commit('LMS_SUPERADMIN_CUSTOMER_RESET')
    },
    lmsSuperadminCustomerRemove ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.subscriptionmanager}/customer/${id}`
        }).then(response => {
          router.push({ name: 'lms-superadmin-customer-list' })
          dispatch('defaultNotificationToastsAdd', {
            title: 'Customer archive',
            message: 'Customer has been successfully archived.',
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Customer archive',
            message: `Customer hasn't been archived.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerUpdate ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        const customerHasCCSService = state.data.services.CCS

        const onResolve = (data) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Customer update',
            message: 'Customer has been successfully updated.',
            status: 'success'
          })
          commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
          commit('LMS_SUPERADMIN_CUSTOMER_UPDATE_DEFAULT')
          resolve(data)
        }

        const onReject = (error) => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Customer update',
            message: `Customer hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        }

        return axios({
          method: 'patch',
          url: `${api.subscriptionmanager}/customers`,
          data: state.data
        }).then(response => {
          if (customerHasCCSService) {
            dispatch('lmsSuperadminCustomerUpdateCCSSettings').then(() => {
              onResolve(response.data)
            }).catch(error => {
              onReject(error)
            })
          } else {
            onResolve(response.data)
          }
        }).catch(error => {
          onReject(error)
        })
      })
    },
    lmsSuperadminCustomerUpdateCCSSettings ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))
        const customerId = data || stateData.id
        const allowedCustomers = stateData.options.ccs.allowedCustomers

        axios({
          method: 'patch',
          url: `${api.courseCatalog}/customer-settings/${customerId}`,
          data: {
            allowedCustomers
          }
        }).then(response => {
          commit('LMS_SUPERADMIN_CUSTOMER_GET_CCSSETTINGS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminCustomerCreate ({ commit, state, dispatch }, redirect = true) {
      return axios({
        method: 'post',
        url: `${api.subscriptionmanager}/customers`,
        data: state.data
      }).then(response => {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Customer create',
          message: 'Customer has been successfully created.',
          status: 'success',
          linkText: 'Go to customer',
          linkTo: {
            name: 'lms-superadmin-customer-edit',
            params: { id: response.data.id }
          }
        })
        commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
        commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
        if (redirect) {
          router.push({ name: 'lms-superadmin-customer-list' })
        }
        return response
      }).catch(function () {
        dispatch('defaultNotificationToastsAdd', {
          title: 'Customer create',
          message: `Customer hasn't been created.`,
          status: 'error'
        })
      })
    },
    //
    lmsSuperadminCustomerSetName ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_NAME', value)
    },
    lmsSuperadminCustomerSetNotes ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_NOTES', value)
    },
    lmsSuperadminCustomerSetStatus ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_STATUS', value)
    },
    lmsSuperadminCustomerSetOptionsEBSHideEventsCatalogue ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_EBS_HIDEEVENTSCATALOGUE', value)
    },
    lmsSuperadminCustomerSetOptionsCCSOpen ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_CCS_OPEN', value)
    },
    lmsSuperadminCustomerSetOptionsCCSAllowedCustomers ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_SET_OPTIONS_CCS_ALLOWEDCUSTOMERS', value)
    },
    lmsSuperadminCustomerRemoveContactById ({ commit }, value) {
      commit('LMS_SUPERADMIN_CUSTOMER_REMOVE_CONTACTBYID', value)
    }
  }
}

export default lmsSuperadminCustomer
