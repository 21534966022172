<template>
    <div :class="['form', 'form__checkbox_vertical', disabled ? 'form__checkbox_vertical--disabled' : '']">
        <ul>
            <li
                v-for="(item, index) in itemsOutput"
                :key="index"
            >
                <label :for="item.value ? item.value : item">
                    <input
                        type="checkbox"
                        class="form__checkbox_vertical_input"
                        :disabled="disabled"
                        :id="item.value ? item.value : item"
                        :name="item.value ? item.value : item"
                        :value="item.value ? item.value : item"
                        v-model="newValue"
                    >
                    <span>{{item.label ? item.label : item | toCapitalize}}</span>
                </label>
            </li>
            <template v-if="itemExtra">
                <li>
                    <label :for="`${toUncapitalise(name)}-item-extra`">
                        <input
                            type="checkbox"
                            class="form__checkbox_vertical_input form__checkbox_vertical_input--itemextra"
                            :id="`${toUncapitalise(name)}-item-extra`"
                            :name="`${toUncapitalise(name)}-item-extra`"
                            @click="emitExtra"
                        >
                        <span>{{ itemExtra }}</span>
                    </label>
                </li>
            </template>
        </ul>
        <template v-if="$slots.default">
            <div class="form__checkbox_vertical_hint">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
export default {
  name: 'form-checkbox-vertical',
  data () {
    return {
      dataValue: [],
      itemExtraValue: ''
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean]
    },
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    items: {
      type: [Array, Object],
      required: true
    },
    itemExtra: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    preselected: {
      type: Array
    }
  },
  computed: {
    newValue: {
      get () {
        return this.value ? this.value : this.dataValue
      },
      set (newValue) {
        this.setDataValue(newValue)
        this.$emit('input', newValue)
      }
    },
    itemsOutput () {
      return this.items.filter((item) => {
        return item.visibility !== undefined ? item.visibility : true
      })
    }
  },
  watch: {
    newValue (value) {
      this.setNewValue(value)
    }
  },
  methods: {
    clearData () {
      this.setDataValue([])
      this.newValue = []
    },
    setDataValue (data) {
      this.dataValue = data
    },
    setNewValue (value) {
      let newValue = this.items.filter((obj) => {
        return obj.value ? obj.value === value[0] : obj === value[0]
      })
      let str
      if (!value.length) {
        str = ''
      } else if (value.length === 1) {
        str = `${this.name}: ${newValue[0].label ? newValue[0].label : newValue[0]}`
      } else {
        str = `${this.name}: ${newValue[0].label ? newValue[0].label : newValue[0]}, ${value.length - 1} more...`
      }
      this.emitSelected(str)
      this.emitFilter(value)
    },
    emitExtra (event) {
      this.$emit('emit-extra', event.target.checked)
    },
    emitSelected (data) {
      this.$emit('emit-selected', data)
    },
    emitFilter (data) {
      this.$emit('emit-filter', data)
    },
    initializePreselectedValue () {
      if (this.preselected && this.preselected.length) {
        this.setNewValue(this.preselected)
        this.setDataValue(this.preselected)
      }
    }
  },
  created () {
    this.initializePreselectedValue()
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/forms/formcheckboxvertical";
</style>
