import axios from 'axios'
import api from '@/api'

const data = {
  pagination: {},
  data: [],
  default: ''
}

const lmsSuperadminWorkflowsLog = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminWorkflowsLog: state => {
      return state.data
    },
    getLmsSuperadminWorkflowsLogMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.id,
          customLabel: item.name,
          value: item.id,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminWorkflowsLogMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
          extraData: item.workspaces
        }
      })
    },
    getLmsSuperadminWorkflowsLogPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    LMS_SUPERADMIN_WORKFLOWS_LOG_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_WORKFLOWS_LOG_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    lmsSuperadminWorkflowsLogGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        queryUrlParams = queryUrlParams.replace('dateStart', 'dateFrom')
        queryUrlParams = queryUrlParams.replace('dateEnd', 'dateTo')
        commit('LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR')
        commit('LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.learningactivities}/workflow/log${queryUrlParams}`
        }).then(function (response) {
          commit('LMS_SUPERADMIN_WORKFLOWS_LOG_SET', response.data.data !== undefined ? response.data.data : response.data)
          commit('LMS_SUPERADMIN_WORKFLOWS_LOG_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  lmsSuperadminWorkflowsLogClear ({ commit }) {
    commit('LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR')
    commit('LMS_SUPERADMIN_WORKFLOWS_LOG_CLEAR_PAGINATION')
  }
}

export default lmsSuperadminWorkflowsLog
